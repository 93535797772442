@import "variables.scss";

#bgprogramming #pageExerciseListPage{
  display: flex;
  max-width: $mainContentScreenSize;
  width: 100%;
  margin: auto;  
  justify-content: center;

  .title-container {
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;

    .img-ai-guide {
      width: 128px;
      padding: 16px;
      margin-right: 16px;
    }

    .write-button-container {
      position: relative;
      display: flex;      
    }
    
  }

  

  .list-container {    
    width: 100%;

    .card-item {      
      
      .card-container {
        position: relative;
        height: 180px;
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;

        .item-title {
          cursor: pointer;
        }

        .item-description {
          cursor: pointer;
          overflow: hidden;
          text-align: left;
          padding-top: 8px;
        }
      }

      .progress-label {
        position: absolute;
        bottom: 8px;
        right: 8px;
        background-color: black;
        padding : 6px 8px;
        border-radius: 4px;
      }

    }
  }
}
  
  
@media screen and (max-width: $mobileMaxWidth) {
  
}
