@import "variables.scss";

#bgprogramming #postEditPage-20240614 {
    max-width: $mainContentScreenSize;
    margin: 64px auto 128px;
    width: 90%;

    .title-text {
        margin : 8px 0px 16px;
    }

    .description-label {
        margin-bottom: 8px;
    }
    .btn-write {
        margin-top : 32px;
        height: 48px;
    }
}