@import "variables.scss";

#bgprogramming #signupPage-240606{    
    height: 100vh;
    align-items: center;
    padding : 24px;

    .logo {
        margin-top: 120px;
        width: 360px;
    }

    .signup-panel {
        margin : 48px 0px 16px;
        max-width: 480px;
        width: 100%;
        border : $defaultBorder;
        border-radius: 4px;
        padding : 32px;

        .input-email {
            margin: 24px 0px;
        }        
    }

    .login-panel {
        max-width: 480px;
        width: 100%;        

        .btn-loginpage {
            cursor: pointer;
        }
    }
}


/** Mobile size **************************************************************/
@media screen and (max-width: $mainContentScreenSize) {
    #spacehub #signupPage-230601{            
        .site-title {
            font-size: 2rem;
        }
    
        .signup-panel {
            margin : 32px 0px 16px;
            padding : 24px;            
        }        
    }
}