@import "variables.scss";

#bgprogramming #layoutMain{
  #mainContainer {
    max-width: $mainContentScreenSize;    
    margin: $defaultMainContentTopSpace auto 80px;
    width: 100%;    
  }
}
  
  
@media screen and (max-width: $mobileMaxWidth) {
  
}
