$defaultMargin : 16px;
$scrollbarSize : 16px;

$defaultFontFamily : "Noto Sans KR";
$defaultTextColor: #25252B;

$primaryColor : #FF7F00;
$defaultButtonColor : #EBEBEB;

$textMuted : #B3B3B3;
$textGray : #88888C;

$defulatBorderColor : #CACCCF;

$h3FontSize : 1.5rem;
$h3FontWeight : 700;
$h4FontSize : 1.125rem;
$h4FontWeight : 700;
$subtitle1FontSize : 0.875rem;
$subtitle1FontWeight : 500;

$navbarHeight : 72px;

$defaultMainContentTopSpace : 32px;
$mainContentScreenSize : 1290px;
$mobileMaxWidth : 1024px;

$defaultBorderColor : #777;
$defaultBorder : 1px solid $defaultBorderColor;