@import "variables.scss";

#bgprogramming #loginPage-240606{    
    align-items: center;
    
    .logo {
        margin-top: 120px;
        width: 360px;
    }

    .login-panel {
        margin : 48px 0px 16px;
        max-width: 480px;
        width: 100%;
        border : $defaultBorder;
        border-radius: 4px;
        padding : 32px;

        .input-password {
            margin: 24px 0px 48px;
        }

        .btn-login {
            border-radius: 30px;
            height: 48px;
            font-weight: bold;
            font-size: 1.1rem;
        }
    }

    .signup-panel {
        max-width: 480px;
        width: 100%;

        .btn-signup {
            cursor: pointer;
        }
    }
}



/** Mobile size **************************************************************/
@media screen and (max-width: $mainContentScreenSize) {
    #spacehub #loginPage-230531{        
        .site-title {            
            font-size: 2rem;
        }
    
        .login-panel {
            margin : 32px 0px 16px;
            padding : 24px;
    
            .input-password {
                margin: 12px 0px 24px;
            }
        }            
    }    
}