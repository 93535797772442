@import "variables.scss";

#bgprogramming #appNavBar{
  .logo {
    cursor: pointer;
  }

  .nav-link {
    cursor: pointer;
  }
  .dropdown-item {
    cursor: pointer;
  }
  
  .nav-right-panel {
    flex-grow: unset;    
  }  
}
  
  
@media screen and (max-width: $mobileMaxWidth) {
  
}
