@import "variables.scss";

#bgprogramming #pageLearningDetail-20240718{
    .title-container {
        display: flex;
        flex-direction: row;
        
    }
    
    .description-container {
        margin-top: 16px;
    }

    .relative-exercise-list-container {
        .exercise-list {
            margin-top: 12px;    
            display: flex;
            flex-direction: row;
            

            .card-container {
                width: 120px;
                height: 68px;
                margin-right: 24px;
                border: 1px solid #444;
                border-radius: 4px;
                cursor: pointer;
                                
                .item-title {
                    $cardMargin : 12px;                    
                    overflow: hidden;
                    margin: $cardMargin;
                    height: calc( 100% - $cardMargin * 2);
                }                
            }
        }        
    }
}
  
  
@media screen and (max-width: $mobileMaxWidth) {
  
}
