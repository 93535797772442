@import "variables.scss";

#bgprogramming #pageLearningList-20240716{  
  .guide-container {
    flex-direction: row;
    align-items: center;

    .btn-write {
      height: 48px;
    }
  }



  .list-container {
    margin-top: 24px;
    .card-item {
      padding: 8px;
      border : 1px solid #777;
      text-align: center;
      box-shadow: 4px 8px 8px rgba(140, 140, 140, 0.2), 0 6px 20px rgba(58, 58, 74, 0.2); /* 그림자 효과 추가 */
      transition: transform 0.2s; /* 호버 애니메이션 추가 */
      height: 240px;
      cursor: pointer;
      overflow: hidden;      

      .MuiPaper-root {
        height: 100%;
      }

      .item-title {        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2; /* number of lines to show */
      }
    }
    .card-item:hover {
      transform: scale(1.05); /* 호버 시 카드 확대 */
      box-shadow: 6px 6px 12px rgba(255, 170, 23, 0.2), 0 6px 20px rgba(58, 58, 74, 0.2); /* 그림자 효과 추가 */
    }

    .card-header {
      flex-direction: row;
    }
    .card-body {
      .item-title {
        margin-bottom: 24px;
      }
      
    }


  }

}
  
  
@media screen and (max-width: 1024px) {
  
}
