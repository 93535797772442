@import "variables.scss";

#bgprogramming #pageHome{
  margin-top: -$defaultMainContentTopSpace;
  
  .section-class  {
    .item-bord {
      display: flex;
      justify-content: center;

      .item-container {
        width : fit-content;

        .item-title {
          width:fit-content;
        }

        .item-description {
          width:fit-content;
        }
      }
    }
  }
 
}
  
  
@media screen and (max-width: 1024px) {
  
}
