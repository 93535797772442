@import "variables.scss";

#bgprogramming #exerciseWritePage-20240615{
    max-width: $mainContentScreenSize;
    margin: 64px auto 128px;
    width: 90%;

    .description-label {
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .title-text {
        margin-top: 8px;
    }
    .btn-write {
        margin-top : 48px;
        height: 48px;
    }
}