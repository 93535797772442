@font-face { font-family: NanumGothicCoding; src: url('NanumGothicCoding.ttf'); } 
$mainTextColor : white;
$subTextColor : #787878;
$buleTextColor : #508CE6;




.shs-text-viewer-container {
	width: 100%;
	overflow-wrap: anywhere;	// setting line break


	///////////////////////////////////////////////////////////////////////////////////////////////////
	// Plain Text Style
	.composite-text {
		text-align : left;
		white-space: pre-wrap;
		font-family: "Maison Neue";
		line-height: 1.31;
		font-size: 16px;
		color : $mainTextColor;
		margin : 0px;

		.large {
			font-size: 18px;
			font-weight: bold;
		}
		.extra-large {
			font-size: 20px;
			font-weight: bold;
		}

		.styled-text {
			position: relative;
			display: flex;
			flex-direction: row;
		}

		.quotation  {
			padding-left: 8px;
		}
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////
	// Image Style
	.shs-image {
		max-width: 480px;
		width : 100%;
		cursor : pointer;
		margin: 16px 0px;
	}

	
	///////////////////////////////////////////////////////////////////////////////////////////////////
	// Youtube Style
	.youtube-player-container {
		width: 100%;
		max-width: 640px;
		margin : 16px 0px;

		.spacehub-youtube {
			width : 100%;

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}

	.youtube-player-container div {
		height : 100%
	}



	///////////////////////////////////////////////////////////////////////////////////////////////////
	// Link Style
	.link-container  {
		margin : 4px 0px;
		padding : 8px;
		display: flex;
		background-color: #f2f2f2;
		border-radius: 8px;
		cursor : pointer;

		.link-preview  {
			width : 65px;
			height: 65px;
			min-width: 65px;
			border-radius: 8px;
			object-fit: cover;
		}

		.info-container {
			padding : 8px 8px 0px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.link-title {
			font-family: "Maison Neue";
			font-size : 16px;
			line-height : 1.23;
			color : $mainTextColor;
			margin-top: 2px,auto;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			word-break: break-word;
			display: -webkit-box;
			white-space: pre-wrap;
			line-height: 1.2;
			font-weight: bold;
		}

		.link-site  {
			font-family: "Maison Neue";
			font-size : 16px;
			line-height : 1.23;
			color : #777;
			text-overflow: ellipsis;
			word-break: break-word;
			white-space: pre-wrap;
			overflow-wrap: break-word;
		}

	}

	.plain-link-container {		
		color: $buleTextColor;
		text-decoration: underline;
		display: inline-block;
		cursor : pointer;
		text-align: left;
		margin-top: 4px;
	}



	///////////////////////////////////////////////////////////////////////////////////////////////////
	// Inline Link Style
	.inline-link {
		color: $buleTextColor;
		text-decoration: underline;
		display: inline-block;
		cursor : pointer;
	}


	.inline-mention, .inline-community {
		color: $buleTextColor;
		text-decoration: none;
		display: inline-block;
		cursor : pointer;
	}
}

