@import "variables.scss";


.mt-8 { margin-top: 8px !important;  }
.mt-12 { margin-top: 12px !important;  }
.mt-16 { margin-top: 16px !important;  }
.mt-24 { margin-top: 24px !important;  }
.mt-32 { margin-top: 32px !important;  }
.ml-4 { margin-left: 4px !important; }
.ml-8 { margin-left: 8px !important; }
.ml-12 { margin-left: 12px !important; }
.ml-16 { margin-left: 16px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-24 { margin-left: 24px !important; }
.ml-32 { margin-left: 32px !important; }

.flex-box {
  display: flex !important;
  align-items: center;  
}

.flex-fill {
  flex-grow: 1 !important; 
}

pre {
  font-size: 1rem;
  overflow: initial;
}


.text-align-center {text-align: center;}

.text-color-secondary { color : #88888C !important;  }

.color-gray { color : #8a8a8a !important;}

.help-text {
  font-size: 10px !important;
  font-weight: 400 !important;
  color: rgba(37, 40, 43, 0.7);
}

.default-primary-button.MuiButtonBase-root {  
  width: 88px;
  height: 40px;
  color : white;
}

.btn.btn-alert-primary {
  background-color: $primaryColor;
  color : white;
  height: 40px;
  border-radius: 4px;
  border : none;
  cursor: pointer;  
  margin-left: 16px;
  min-width: 90px;
}

.btn.btn-alert-default {
  background-color: $defaultButtonColor;
  color : $defaultTextColor;
  height: 40px;
  border-radius: 4px;
  border : none;
  cursor: pointer;  
  margin-left: 16px;
  min-width: 90px;
}

// Mui Form
.form {
  .select-form-label {  // - Select form InputLabel 위치 보정
    margin-left: -14px !important;
    margin-top: 8px !important;
  }

  .uploaded-file-container {
    align-items: center;
    justify-content: center;

    padding: 1rem;
    // border-radius: 0.5rem;
    // background-color: #f5f5f5;

    .uploaded-file-thumbnail {
      width: 32px;
      height: 32px;
    }

    .uploaded-file-box {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      width: 100%;

      .uploaded-file-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
  
        .uploaded-file-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // word-break: break-all;
        }
      }
    }
  }
}




// for Toast Line Feed
.Toastify__toast-body {
  	white-space: pre-line;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// 									Drawer Presentation										 	 //
///////////////////////////////////////////////////////////////////////////////////////////////////
.bgprogramming-drawer-presentation {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.3);
  left: 0;  
  top : 0;
  z-index: 1000;  // sweet alert z-index는 1060이다
}

