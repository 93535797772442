@import "variables.scss";

#bgprogramming #learningWritePage-20240716{
    max-width: $mainContentScreenSize;
    
    width: 90%;

    .title-label {
        margin-top: 16px;
    }
    .title-text {
        margin-top: 8px;
    }

    .description-label {
        margin-top: 16px;
        margin-bottom: 8px;
    }
    
    .btn-write {
        margin-top : 48px;
        height: 48px;
    }
}